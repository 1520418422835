@import '@/styles/mixins.scss';

.wrapper {
    @include flex-column;

    gap: 40px;

    padding: 40px 0;
}

.content-section-wrapper {
    --gap: 80px;

    display: grid;
    grid-template-columns: repeat(2, calc(50% - var(--gap) / 2));
    grid-template-rows: 1fr;

    gap: var(--gap);
}

.image-wrapper {
    &.left {
        grid-column: 1;
    }

    &.right {
        grid-column: 2;
    }
}

.image {
    width: stretch;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 20px;

    grid-row: 1;

    &.left {
        grid-column: 2;
    }

    &.right {
        grid-column: 1;
    }
}

.title {
    @include headline-2;
    color: var(--colors-gray-dark-90);

    margin: 0;
}

.description {
    @include text-M;
    color: var(--colors-gray-dark-50);

    margin: 0;
}

.list {
    @include flex-column;
    gap: 20px;
}

.list-item {
    @include flex-row;
    @include text-M;

    align-items: center;

    gap: 12px;
    color: var(--colors-gray-dark-50);
}

.check {
    width: 20px;
    height: 20px;
}

.button {
    margin-top: 20px;

    width: fit-content;
}

@media screen and (max-width: 1280px) {
    .content-section-wrapper {
        --gap: 32px;
    }
}

@media screen and (max-width: 960px) {
    .content-section-wrapper {
        --gap: 20px;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);

        text-align: center;
    }

    .image-wrapper {
        grid-column: 1 !important;
    }

    .image {
        width: 500px;
    }

    .content-wrapper {
        grid-row: 2 !important;
        grid-column: 1 !important;

        align-items: center;
    }

    .title,
    .description {
        text-align: center;
    }
}

@media screen and (max-width: 764px) {
    .image {
        width: 100%;
    }
}

@media screen and (max-width: 524px) {
}
