@import '@/styles/mixins.scss';

.wrapper {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 15px));
    gap: 30px;

    align-items: center;

    padding: 20px 0;
}

.content-wrapper {
    @include flex-column;
    margin-right: 50px;
    padding: 52px 0;

    gap: 16px;
}

.title {
    @include hero-headline;
    color: var(--colors-primary);

    margin: 0;
}

.description {
    @include text-L;
    color: var(--colors-gray-dark-50);
}

.buttons {
    @include flex-row;
    gap: 24px;

    margin-top: 24px;
}

.icon-text-box-wrapper {
    @include flex-column;
    gap: 8px;
    margin-top: 24px;
}

.icon-text-box {
    display: grid;
    grid-template-columns: 24px 1fr;
    gap: 8px;

    height: 34px;

    align-items: center;

    span {
        @include text-S;
        color: var(--colors-gray-dark-50);
        margin: 0;
    }
}

.picture-wrapper {
    @include flex-center;
    position: relative;
    width: 630px;
    flex-shrink: 0;
    margin-left: auto;

    &.mobile {
        display: none;
    }
}

.picture {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
    border-radius: 16px;
    max-height: 600px;
}

@media screen and (max-width: 1280px) {
    .wrapper {
        grid-template-columns: repeat(2, calc(50% - 20px));
        gap: 40px;
    }

    .content-wrapper {
        margin: 0;
        padding: 0;
    }

    .picture-wrapper {
        width: stretch;
    }
}

@media screen and (max-width: 960px) {
    .wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;

        gap: 0;
    }

    .content-wrapper {
        margin: 0;
    }

    .title,
    .description {
        text-align: center;
    }

    .buttons {
        justify-content: center;
        margin-top: 8px;
    }

    .picture-wrapper {
        width: 444px;
        margin: auto;

        grid-row: 1;
    }
}

@media screen and (max-width: 764px) {
}

@media screen and (max-width: 524px) {
    .wrapper {
        grid-template-rows: 1fr;
    }

    .picture-wrapper {
        display: none;

        &.mobile {
            display: block;
            width: 310px;
        }
    }
}
