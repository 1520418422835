@import '@/styles/mixins.scss';

.wrapper {
    @include flex-column;
    gap: 32px;

    padding: 80px 0;

    position: relative;
}

.background-shadow {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);

    width: 535px;
    height: 148px;

    border-radius: 500px;
    background: linear-gradient(177deg, rgba(249, 180, 171, 0.15) -75.45%, rgba(138, 184, 247, 0.15) 116.1%);
    opacity: 0.5;
    filter: blur(30px);
}

.title {
    @include headline-2;
    text-align: center;

    div:first-child {
        color: var(--colors-primary);
    }

    div {
        color: var(--colors-gray-dark-30);
    }
}

.scroller-wrap {
    @include flex-row;
    gap: 44px;

    width: 100%;
    height: 49px;
    overflow: hidden;

    user-select: none;
    mask-image: linear-gradient(
        var(--mask-direction, to right),
        hsl(0 0% 0% / 0),
        hsl(0 0% 0% / 1) 20%,
        hsl(0 0% 0% / 1) 80%,
        hsl(0 0% 0% / 0)
    );
}

.scroller-group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 44px;
    min-width: 100%;
    height: 49px;
    animation: scroll-x var(--duration) linear infinite;
}

.item {
    @include flex-center;
    @include subtitle-L;
    display: inline-flex;

    padding: 8px 18px;
    gap: 4px;
    border-radius: 8px;
    background: var(--white);

    white-space: nowrap;

    color: var(--colors-gray-dark-20);
    text-align: center;
}

@keyframes scroll-x {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - 44px));
    }
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 960px) {
    .wrapper {
        padding: 60px 0;
    }

    .scroller-wrap,
    .scroller-group {
        gap: 24px;
    }

    .background-shadow {
        height: 90px;
        top: 150px;
    }
}

@media screen and (max-width: 764px) {
    .section-wrap {
        padding: 0;
    }

    .title {
        padding: 0 40px;
    }

    .background-shadow {
        top: 130px;
    }
}

@media screen and (max-width: 524px) {
    .title {
        padding: 0 24px;
    }

    .background-shadow {
        top: 180px;
    }
}
