@import '@/styles/mixins.scss';

.wrapper {
    padding: 40px 0;

    position: relative;
}

.title {
    @include headline-2;
    text-align: center;
}

.steps-wrapper {
    @include flex-column;
    gap: 22px;
    margin: 32px auto 22px;

    width: 774px;
}

.step {
    @include flex-center;
    gap: 16px;

    background: white;
    border: 1px solid var(--colors-gray-light-90);
    border-radius: 9px;

    box-shadow: var(--shadow-card-dark);

    padding: 16px;

    &:nth-child(2n) {
        margin: 0 40px;
    }

    &:nth-child(3n) {
        margin: 0 80px;
    }
}

.step-number-box {
    @include headline-1;
    @include flex-center;
    width: 72px;
    height: 72px;

    background: var(--colors-primary-light-95);
    border-radius: 8px;

    color: var(--colors-primary);

    flex-shrink: 0;
}

.step-content {
    @include flex-column;
    gap: 4px;
}

.step-title {
    @include headline-6;
    color: var(--colors-gray-dark-70);
}

.step-description {
    @include text-S;
    color: var(--colors-gray-dark-40);
}

.grey-background {
    background: var(--colors-gray-light-95);
    border-radius: 16px;

    position: absolute;
    top: 208px;
    left: 0;
    right: 0;
    bottom: 40px;

    z-index: -1;
}

@media screen and (max-width: 1280px) {
    .wrapper {
        padding: 60px 0;
    }

    .grey-background {
        top: 188px;
    }
}

@media screen and (max-width: 960px) {
    .steps-wrapper {
        width: 100%;
        padding: 0 33px;

        margin: 32px auto 0;
    }
}

@media screen and (max-width: 764px) {
    .steps-wrapper {
        padding: 0;
    }

    .step {
        margin: 0 !important;
    }

    .grey-background {
        display: none;
    }
}

@media screen and (max-width: 524px) {
    .step {
        flex-direction: column;
        align-items: flex-start;
    }

    .step-number-box {
        width: 52px;
        height: 52px;
    }
}
