@import '@/styles/mixins.scss';

.wrapper {
    @include flex-column;
    gap: 32px;

    padding: 80px 0;

    position: relative;

    &.vertical {
        align-items: center;
        flex-direction: row;

        height: 284px;
        padding: 40px 0;
        max-width: 932px;
        margin: auto;
    }
}

.title {
    @include headline-2;
    text-align: center;
    color: var(--colors-primary);
}

.scroller-wrap {
    @include flex-row;
    gap: 44px;

    width: 100%;
    height: 49px;
    overflow: hidden;

    margin: auto;

    user-select: none;
    mask-image: linear-gradient(
        var(--mask-direction, to right),
        hsl(0 0% 0% / 0),
        hsl(0 0% 0% / 1) 20%,
        hsl(0 0% 0% / 1) 80%,
        hsl(0 0% 0% / 0)
    );

    &.vertical {
        flex-direction: column;
        gap: 32px;
        height: auto;
        max-height: 100%;
        min-width: 360px;

        mask-image: linear-gradient(
            var(--mask-direction, to bottom),
            hsl(0 0% 0% / 0),
            hsl(0 0% 0% / 1) 30%,
            hsl(0 0% 0% / 1) 70%,
            hsl(0 0% 0% / 0)
        );
    }
}

.scroller-group {
    --duration: 100s;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 44px;
    min-width: 100%;
    height: 49px;
    animation: scroll-x var(--duration) linear infinite;

    &.vertical {
        --duration: 12s;
        flex-direction: column;
        gap: 32px;
        height: 100%;
        animation: scroll-y var(--duration) linear infinite;
    }
}

.item {
    @include flex-center;
    @include subtitle-L;
    display: inline-flex;
    gap: 4px;
    border-radius: 8px;
    background: var(--white);

    white-space: nowrap;

    color: var(--colors-gray-dark-20);
    text-align: center;
    filter: grayscale(100%);

    transition: 200ms ease-out;

    &:hover {
        filter: grayscale(0%);
    }
}

.logo {
    height: 40px;
}

.quote-wrapper {
    @include flex-column;
    gap: 12px;

    padding: 0 20px 0 32px;
    border-left: 1px solid var(--colors-primary-light-80);
}

.quote-title {
    @include headline-2;
    color: var(--colors-primary);
}

.quote-content {
    @include subtitle-L;
    color: var(--colors-gray-dark-40);

    p {
        margin: 0;
    }
}

.quote-attribution {
    text-align: right;
    margin-top: 4px;

    color: var(--colors-gray);
}

@keyframes scroll-x {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - 44px));
    }
}

@keyframes scroll-y {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(calc(-100% - 32px));
    }
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 960px) {
    .wrapper {
        padding: 60px 0;

        &.vertical {
            flex-direction: column;
            height: auto;
            padding: 60px 40px;
        }
    }

    .scroller-group {
        --duration: 50s;

        &.vertical {
            --duration: 50s;

            flex-direction: row;
            gap: 44px;
            min-width: 100%;
            height: 49px;
            animation: scroll-x var(--duration) linear infinite;
        }
    }

    .scroller-wrap.vertical {
        flex-direction: row;
        width: -webkit-fill-available;
        height: 49px;
        gap: 44px;

        mask-image: linear-gradient(
            var(--mask-direction, to right),
            hsl(0 0% 0% / 0),
            hsl(0 0% 0% / 1) 20%,
            hsl(0 0% 0% / 1) 80%,
            hsl(0 0% 0% / 0)
        );
    }

    .scroller-wrap,
    .scroller-group {
        gap: 24px;
    }
}

@media screen and (max-width: 764px) {
    .section-wrap {
        padding: 0;
    }

    .scroller-group {
        --duration: 30s;
    }

    .wrapper.vertical {
        padding: 40px;
    }

    .title {
        padding: 0 40px;
    }
}

@media screen and (max-width: 524px) {
    .title {
        padding: 0 24px;
    }

    .wrapper.vertical {
        padding: 24px;
    }
}
