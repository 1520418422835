@import '@/styles/mixins.scss';

.section-wrapper {
    background: url('/images/flat-gradient.png');
    background-size: cover;
    background-position: center;

    margin: 20px 0;

    &:last-of-type {
        margin-bottom: 80px;
    }
}

.wrapper {
    @include flex-center;
    flex-direction: column;
    padding: 40px 0;

    position: relative;
}

.content-wrapper {
    @include flex-column;
    gap: 16px;

    width: 770px;
}

.title {
    @include headline-2;
    color: white;

    margin: 0;
    text-align: center;
}

.description {
    @include text-L;
    color: white;
    text-align: center;
}

.form-wrapper {
    @include flex-row;
    gap: 12px;

    margin-top: 32px;
}

.input {
    @include text-M;

    padding: 0 13px;
    width: 256px;
    height: 42px;

    background: white;
    border: 1px solid var(--colors-gray-light-70);
    border-radius: 8px;

    color: var(--colors-gray-dark-40);

    outline: none;
}

.button {
    border-radius: 8px;
}

.image {
    position: absolute;
    right: 0;
    bottom: -20px;
}

.subscribed {
    @include headline-5;
    color: white;

    margin-top: 32px;
}

.error {
    @include caption-M;
    color: white;
    margin-top: 12px;
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 960px) {
    .content-wrapper {
        width: 100%;
    }

    .wrapper {
        padding: 40px 0;
    }

    .image {
        display: none;
    }
}

@media screen and (max-width: 764px) {
}

@media screen and (max-width: 524px) {
    .form-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;

        gap: 12px;
    }

    .input,
    .button {
        width: 100%;
    }
}
